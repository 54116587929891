<template>
  <div>
    <v-container id="forgot" class="fill-height justify-center" tag="section">
      <!--begin::Content header-->
      <div
        class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
      >
        <router-link
          class="font-weight-bold font-size-3 ml-2"
          :to="{ path: '/pages/login' }"
        >
          Sign In!
        </router-link>
      </div>
      <!--end::Content header-->
      <base-material-card
        color="success"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template #heading>
          <div class="text-center">
            <h3 class="font-size-h1 text-white">Forgot Password?</h3>
          </div>
        </template>

        <!--begin::Forgot Password-->
        <div v-if="emailSent" class="login-form login-signin">
          <div class="text-center mb-5 mb-lg-5">
            <h3 class="text-muted font-weight-semi-bold">
              An Email has been sent to your address if an account exists.
              Please follow the login instructions to reset your password
            </h3>
          </div>
        </div>

        <div v-if="!emailSent" class="login-form login-signin">
          <div class="text-center mb-5 mb-lg-5">
            <p class="font-weight-semi-bold">
              Send an email for password reset
            </p>
          </div>

          <!--begin::Form-->
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div
              role="alert"
              :class="{ show: is_error }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                {{ err_message }}
              </div>
            </div>

            <b-form-group
              id="example-input-group-1"
              label=""
              label-for="example-input-1"
            >
              <b-form-input
                id="example-input-1"
                v-model="$v.form.email.$model"
                class="form-control form-control-solid h-auto py-5 px-6"
                name="example-input-1"
                :state="validateState('email')"
                aria-describedby="input-1-live-feedback"
                placeholder="Email address"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback">
                Email is required and a valid email address.
              </b-form-invalid-feedback>
            </b-form-group>

            <!--begin::Action-->
            <div class="form-group d-flex flex-wrap flex-center">
              <button
                ref="kt_login_signup_submit"
                type="submit"
                class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              >
                Submit
              </button>
              <button
                class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                @click="$router.push('/pages/login')"
              >
                Cancel
              </button>
            </div>
            <!--end::Action-->
          </b-form>
          <!--end::Form-->
        </div>
        <!--end::Signup-->
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FORGOT_PASSWORD } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "Forgotpassword",
  mixins: [validationMixin],
  data() {
    return {
      emailSent: false,
      is_error: false,
      err_message: "",
      form: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send email for password reset
        this.$store
          .dispatch(FORGOT_PASSWORD, {
            email: email,
          })
          .then(() => {
            this.emailSent = true;
            this.$router.push({ path: "/dashboard" });
          })
          .catch(() => {
            this.is_error = true;
            this.err_message = "This email does not exist.";
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
